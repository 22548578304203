<template>
    <section>

        <div class="closeSearchBarOnClick" @click="modalCity = false; modalProvince = false"></div>

        <b-carousel
            v-model="carousel"
            :animated="animated"
            :has-drag="drag"
            :autoplay="autoPlay"
            :pause-hover="pauseHover"
            :pause-info="pauseInfo"
            :pause-info-type="pauseType"
            :interval="interval"
            :repeat="repeat"
            >
            <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
                <section :class="`hero is-medium bg-${carousel.background} is-bold`">
                    <div class="hero-body has-text-centered">
                        <img
                            src="@/assets/img/logoblancocentrado.png"
                            alt="Corralon central"
                            width="300px"
                            class="mb-1"
                        >
                        <h1 class="title has-text-white fontWeigth300 letterSpacing1 font2">
                            {{carousel.title}}
                        </h1>
                         <h1 class="title is-5 has-text-white fontWeigth300 letterSpacing1 font2">
                           Buscá tu provincia y ciudad para solicitar un presupuesto
                           <font-awesome-icon class="" :icon="['fa', 'search-location']"  />
                        </h1>
                          <h1 class="title is-5 has-text-white fontWeigth300 letterSpacing1 font2">
                           Si tu localidad no aparece es porque no posee corralones registrados
                        </h1>                       
                        <div class="searchBarContainer">
                            <div class="mb-0 is-inline-block wEspecial">
                            <p class="colorWhite floatLeft tituloInputBarraBusqueda">
                                Provincia <span v-show="setProvinceState === false"> | Seleccione de la lista</span>
                            </p>
                            <b-field class="mb-0">
                                <b-input
                                    type="text"
                                    placeholder="Buscar provincia..."
                                    custom-class="barraBusqueda"
                                    v-model="province"
                                    autocomplete="off"
                                    @focus="modalProvince = true, modalCity = false"
                                    >
                                </b-input>
                            </b-field>
                            <div v-if="filteredProvinces && modalProvince">
                                <!--  v-if="province.length > 0" -->
                                <ul class="searchBarProvinces">
                                    <li class="listCitys"
                                        v-for="(filteredProvince, index) in filteredProvinces" :key="index"
                                        @click="setProvince(filteredProvince)">{{ filteredProvince.name }}</li>
                                </ul>
                            </div>    
                            </div>
                            <div class="mb-0 is-inline-block wEspecial2 marginLeftBarra">
                            <p class="colorWhite floatLeft tituloInputBarraBusqueda">
                                Ciudad <span v-show="setCityState === false"> | Seleccione de la lista</span>
                            </p>
                            <b-field class="mb-0 firstLetter">
                                <b-input
                                    type="text"
                                    placeholder="Buscar ciudad..."
                                    custom-class="barraBusqueda lowerCaseInput"
                                    :disabled="setProvinceState === false"
                                    v-model="city"
                                    autocomplete="off"
                                    @focus="modalCity = true, modalProvince = false"
                                    >
                                </b-input>
                            </b-field>    
                            <div v-if="filteredCitys && modalCity">
                                <ul class="searchBarCitys" >
                                    <li class="listCitys firstLetter"
                                        v-for="(filteredCity, index) in filteredCitys" :key="index"
                                        @click="setCity(filteredCity)"
                                        v-if="filteredCity.farmyardCount > 0">
                                        <span  class="firstLetter" ></span>
                                            {{ filteredCity.name }} 
                                            <span class="is-hidden">Sin </span> 
                                            <span class="colorSuccess fontWeigth700 is-hidden">{{filteredCity.farmyardCount}} corralones</span> 
                                            
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            </div>

                            <b-button
                                type="is-success"
                                native-type="button"
                                class="searchBarButton title is-5"
                                :disabled="setCityState === false"
                                @click="requestBudget({city, province, emailCorralones, cityId})"
                                >
                                Solicitar presupuesto
                                <font-awesome-icon icon="forward" class="flechaBoton" /> <span style="opacity:0">a</span>
                            </b-button>
                        </div>


                        <br>
                        <p class="title is-4 has-text-white fontWeigth300 letterSpacing1 font2">
                            {{carousel.subtitulo}}
                        </p>
                    </div>
                    <br><br><br>
                </section>
            </b-carousel-item>
        </b-carousel>
        
    </section>
</template>

<script>

import { mapActions, mapState } from "vuex"

export default {
    
    
    name: 'CmpSlider',

    data() {
        return {
            // Searchbar
            province: '',
            provinces: [],
            provincesId:'',
            filteredProvinces: {name:'name',id:'id'},
            modalProvince: false,
            setProvinceState: false,

            city: '',
            cityId: null,
            citys: null,
            filteredCitys: [{name}],
            modalCity: false,
            setCityState: false,
            cityFarmyardCount: '',

            // Email corralones
            emailCorralones: [],
            arrayEmail: [],
            
            // Carousel
            carousel: 0,
            animated: 'slide',
            drag: false,
            autoPlay: true,
            pauseHover: true,
            pauseInfo: false,
            repeat: true,
            pauseType: 'is-primary',
            interval: 3000,
            carousels: [

                { title: 'Recibí presupuestos de materiales con un solo click', subtitulo: 'Un corralon, todos los corralones',
                background: 'img1' },

            ]
        }
    },

    mounted() {
          this.getProvince();
          this.getCity();
    },

    methods: {

        ...mapActions('searchBar',['requestBudget']),

        
    
        info(value) {
            this.carousel = value
            this.$buefy.toast.open({
                message: `This Slide ${value} !`,
                type: 'is-info'
            })
        },
     
        getProvince(){
            this.axios.get('/provinces/')
            .then(res => {       
                const arrayProvincias = res.data;  
                // Construyo el Array provincia
                this.provinces =  arrayProvincias.map(({ id, name }) => ({ id, name }));
                this.filterProvinces();
            })
            .catch(error => {
                this.provinces = [{name: 'No se encontraron provincias'}]
            })
        },

        filterProvinces(){
    

            if (this.province.length == 0) {
            this.filteredProvinces = this.provinces;
            }

            const provincesNameId = this.provinces;

            // Tengo que averiguar como aplicar un filtro sobre un objeto array
            this.filteredProvinces = provincesNameId.filter(province => {
                    return province.name.toLowerCase().includes(this.province.toLowerCase());
            })
            //console.log('Filtrado p')
            //console.log(this.filteredProvincesLowerCase)

            //console.log('Fuera del filtro')
            //console.log(this.filteredProvincesLowerCase)
            //this.filteredProvinces = this.filteredProvincesLowerCase.slice(0, 6);

        },

        setProvince(province) {
            //console.log(province)
            this.province = province.name;
            this.modalProvince = false;
            this.provincesId = province.id;

            this.setProvinceState = true;
            
            this.getCity();
        },
        
        getCity(){

            if(this.provincesId != '') {
            this.axios.get('/provinces/'+this.provincesId+'/cities/farmyardCount')
                .then(res => {    
                    const arrayCitys = res.data;  
                    this.citys =  arrayCitys.map(({ name, farmyardCount, id }) => ({ name, farmyardCount, id }));
                   // console.log(this.citys)
                    this.filterCitys();
                })
                .catch(error => {
                    this.citys = [{name: 'No se encontraron ciudades'}]
                })
            }  
         
        },
        
        filterCitys() {

            if (this.city.length == 0) {
                this.filteredCitys = this.citys;
            }

            this.filteredCitys = this.citys.filter(city => {
                return city.name.toLowerCase().includes(this.city.toLowerCase());
            })
            // console.log('Filtrado')
            // console.log(this.filteredCitysLowerCase)

          //  this.filteredCitys = this.filteredCitysLowerCase.slice(0, 6);
        },


        setCity(city) {
            this.setProvinceState = true,
            this.city = city.name;
            this.cityId = city.id;
           console.log('Home city id ' + this.cityId)
            this.modalCity = false;
            this.setCityState = true;
            this.cityFarmyardCount = city.farmyardCount;
            // Una vez selecciona la ciudad, traigo los Emails
            this.getEmails()
        },

      getEmails(){

            this.axios.get('/cities/'+ this.cityId +'/mails')
                .then(res => {   
                //console.log('Respuesta') 
                //console.log(res.data)
                const arrayEmail = res.data;  
    
                // Construyo el Email
                this.arrayEmail =  arrayEmail.map(({ email }) => ({ email }));
 
                this.emailCorralones = this.arrayEmail.map(function(el) {
                    return el.email;
                 });
                 //console.log(this.emailCorralones)
                })
                .catch(error => {
                    this.citys = [{name: 'No se encontraron ciudades'}]
                })         
        },

        
    },

    watch: {
        city() {
            this.filterCitys();
        },
        province() {
            this.filterProvinces();
        }
    },

    computed: {
         ...mapState('searchBar',['citySelected','provinceSelected','cityIdSelected','emailCorralonesSelected']),
         ...mapState('generalFunctions',['error', 'loader', 'colorLoader'])
    }


}
</script>

<style>
.p-2[data-v-b3b50172] {
    padding: 0px!important;
}
    .firstLetter {
        text-transform: lowercase;
    }
    .firstLetter:first-letter {
        text-transform: uppercase!important;
    }

    .lowerCaseInput {
        text-transform: lowercase!important;
    }


    .button[disabled], fieldset[disabled] .button {
        background-color: white;
        border-color: #dbdbdb;
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: 0.8;
    }

    .searchBarButton {
        width:100%;
        z-index:3;
        border-radius:4px;
        margin-top:5px;
    }

    .closeSearchBarOnClick2 {
        background-color:transparent;
        position:absolute;
        top: 0;
        z-index:1;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .closeSearchBarOnClick {
        background-color:transparent;
        position:absolute;
        top: 0;
        z-index:1;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .searchBarContainer {
        max-width:760px;
        margin:auto;
        display:block;
        position:relative;
        
    }

    .searchBarProvinces {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:49%;
        cursor:pointer;
        max-height:200px;
        overflow:auto;
    }
    /* Con overflwo y barrita
        .searchBarProvinces {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:49%;
        cursor:pointer;
        max-height:200px;
        overflow:auto;
    }
    */
    .searchBarTwo {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:6;
        padding-top:10px;
        padding-bottom:10px;
        width:100%;
        cursor:pointer;
        padding:30px;
        text-align:center;
                max-height:200px;
        overflow:auto;
    }
/* Con overflow y barrita
    .searchBarCitys {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:49%;
        cursor:pointer;
        max-height:230px;
    }
*/
    .searchBarCitys {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:49%;
        cursor:pointer;
        max-height:200px;
       overflow:auto;
    }

    .barraBusqueda {
        z-index:2;
        width:100%;
        margin:auto;
        height: 3em;
        display:inline-block;
        border-radius:4px;
    }
    .tituloInputBarraBusqueda {
        text-align:left!important;
        font-size:14px;
    }

    .marginLeftBarra {
        margin-left:2%;
    }

    .wEspecial{
        width:49%;
    }

     .wEspecial2 {
        width:49%;
    }

    @media (max-width:720px) {
    .wEspecial{
        width:100%;
    }
    .wEspecial2 {
        width:100%;
    }
        .marginLeftBarra {
        margin-left:0%;
        margin-top:8px;
    }
        .searchBarButton {
        margin-top:8px;
    }
        .searchBarProvinces {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:100%;
        cursor:pointer;
    }
        .searchBarCitys {
        color:rgb(44, 44, 44);
        background-color:rgb(240, 240, 240);
        border-radius:3px;
        display:block;
        margin:auto;
        position:absolute;
        z-index:5;
        padding-top:10px;
        padding-bottom:10px;
        width:100%;
        cursor:pointer;
    }

    
    }


    .inputMateriales {
        z-index:2;
        width:100% !important;
        margin:auto;
        height: 3em;
        display:inline-block;
        border-radius:4px;
    }

    .inputMaterialesW70 {
        width:70% !important;
        display:inline-block;
    }

    .inputMaterialesW60 {
        width:60% !important;
        display:inline-block;
    }



    .correctZindex {
        z-index:6 !important;
        position:relative!important;
 
    }

    .widthDivInput {
        width:68%;
    }

    .botonMasMenosMateriales {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
        cursor:pointer;
    }

    
    .botonMasMenosMateriales:hover {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }

    @media (max-width:590px) {

            .inputMaterialesW60 {
        width:59% !important;
        display:inline-block;
    }


        
    .widthDivInput {
        width:38%;
    }

    .botonMasMenosMateriales {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }

    
    .botonMasMenosMateriales:hover {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }

    
    }


@media (max-width:420px) {

            .inputMaterialesW60 {
        width:55% !important;
        display:inline-block;
    }


        
    .widthDivInput {
        width:38%;
    }

    .botonMasMenosMateriales {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }

    
    .botonMasMenosMateriales:hover {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }

    
    }

    .botonMasMenosMateriales:focus {
        border:0px solid #333;
        color:#FFF;
        height: 47px;
        top:12px;
        border-radius:4px;
        width:20px;
        margin-left:5px;
        margin-right:5px;
        outline:none!important;
    }



    .inputCantidad100 {
        z-index:2;
        width:100% !important;
        margin:auto;
        height: 3em;
        border-radius:4px;
        text-align:center;
    }

    .inputCantidad {
        z-index:2;
        width:80% !important;
        margin:auto;
        height: 3em;
        border-radius:4px;
    }

   .inputCantidadW30 {
        width:30% !important;
        display:inline-block;
    }

   .inputCantidadW40 {
        display:inline-block;
        margin-left:10px;
        width:38% !important;
    }


    .inputRequestBudget {
        z-index:3;
        height: 3em;
        display:block;
        border-radius:4px;
        border:0px solid rgb(0, 0, 0) !important;
        box-shadow: 0px 0px 0px 0px !important;
    }

    .inputRequestBudget:focus {
        z-index:3;
        height: 3em;
        display:block;
        border-radius:4px;
        border:1px solid #7957d5!important;
        box-shadow: 0px 0px 0px 0px !important;
    }


    .listCitys {
        margin-top:5px;
        padding-bottom:5px;
        border-bottom: 1px solid rgb(248, 248, 248);
        text-transform:capitalize!important;
    }

    .mb-0 {
        margin-bottom:0px!important;
    }

    @media screen and (min-width: 769px) {
    
    .hero.is-medium .hero-body {
    padding-top: 11rem !important;
    }

    }

    .carousel {
    min-height: 520px !important;
    position: relative;
    }

    .carousel .carousel-items {
    min-height: 520px !important;
    }

    .hero {
    min-height: 520px !important;
    }

    .bg-img1 {
     background-image: url("../../../assets/img/slider/1.jpg");
     background-size:cover;
    }

    .bg-img2 {
     background-image: url("../../../assets/img/slider/2.jpg");
    background-size:cover;
    }

    /* Colors bg */
    /* Colors bg */

    .bgColorPrimary {
        background-color:#8752aa!important;
    }

    .bgColorSecondary {
        background-color:#ff7850!important;
    }

    .bgColorTertiary {
        background-color:#ffc627!important;
    }

    .bgColorSoftRed {
        background-color:#f64444!important;
    }

    .bgColorSoftBlue {
        background-color:#3d40fd!important;
    }

    .bgColorGrey {
        background-color:#e7e7e7!important;
    }

    /* Texto */
    /* Texto */

    .fontWeigth300 {
        font-weight:300!important;
    }

    .fontWeigth400 {
        font-weight:400!important;
    }

    .fontWeigth600 {
        font-weight:600!important;
    }

    .fontWeigth700 {
        font-weight:700!important;
    }



</style>